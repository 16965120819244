.product {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(255, 255, 255);
  border-radius: 4px;
  margin: 8px 8px;
  padding: 6px;
  width: 100%;
  max-height: 310px;
  min-width: 280px;
  max-width: 310px;
  border: 1px solid rgb(250, 250, 250);
  z-index: 1;
  text-align: center;
  box-shadow: rgb(172, 172, 172) 1px 1px 1.8px;
}

.product__info {
  margin: 2px 2px 4px 2px;
}

.product__info > p {
  margin: 0px;
  padding: 0px;
}

#product__image {
  max-height: 150px;
  min-height: 100px;
  width: 88%;
  object-fit: contain;
}

@media screen and (max-width: 570px) {
  #product__image {
    max-height: 136px;
    min-height: 100px;
    width: 88%;
    object-fit: contain;
  }
  .product {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(255, 255, 255);
    border-radius: 4px;
    margin: 8px 8px;
    padding: 6px;
    width: 100%;
    max-height: 280px;
    min-width: 240px;
    max-width: 280px;
    border: 1px solid rgb(250, 250, 250);
    z-index: 1;
    text-align: center;
    box-shadow: rgb(172, 172, 172) 1px 1px 1.8px;
  }
}

.cursor__pointer:hover {
  cursor: pointer;
}

.product > div > p > small {
  margin: 0px 2px;
}

.product__button {
  background: #b31a1a;
  border: 1px solid;
  border-radius: 3px;
  margin-top: 12px;
  margin-bottom: 8px;
  border-color: #af2c2c #a51919 #bb2525 #ce0c0c;
  color: rgb(255, 255, 255);
  padding: 5px 8px;
  text-align: center;
  font-size: 0.8rem;
  box-shadow: rgb(111, 112, 112) 1px 1px 2px;
  /* -webkit-transition-duration: 0.4s; /* Safari */
  transition: background-color 2s, border-color 0.3, color 0.8s,
    transform 4 ease-in;
  outline: none;
  cursor: pointer;
  transition: all 75ms ease-in-out;
}

.product__button:hover {
  background: #b52b2b;
  /* background: #b31a1a; */
  border: 1px solid;
  border-radius: 3px;
  margin-top: 12px;
  margin-bottom: 8px;
  border-color: #af2c2c #a51919 #bb2525 #ce0c0c;
  color: rgb(255, 255, 255);
  padding: 5px 8px;
  text-align: center;
  font-size: 0.8rem;
  box-shadow: rgb(180, 180, 180) 1px 1px 2px;
  /* box-shadow: rgb(111, 112, 112) 1px 1px 2px; */
  transition: background-color 2s, border-color 0.3, color 0.8s,
    transform 4 ease-in;
  outline: none;
  cursor: pointer;
}

.product__button:active {
  background: #ffb116;
  border: 1px solid;
  border-radius: 3px;
  margin-top: 12px;
  margin-bottom: 8px;
  border-color: #e79d09 #eca008 #f4a508 #f2a408;
  color: #b31;
  padding: 5px 8px;
  text-align: center;
  font-size: 0.8rem;
  box-shadow: rgb(111, 112, 112) 1px 1px 1px;
  transform: translateY(0.5px);
  outline: none;
  cursor: pointer;
  /* transition-duration: all 2s ease-in-out; */
}

.display__comment {
  display: none;
  font-size: 0.75rem;
}
