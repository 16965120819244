.basket {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.basket__image {
  z-index: -1;
  margin-bottom: -580px;
}

.basket__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.98);
  max-width: 800px;
  min-height: 80vh;
  width: 90%;
  border-radius: 0.25rem;
  margin: 1rem;
  margin-bottom: 1.4rem;
  padding: 1rem;
  border: 0.125rem solid rgb(190, 19, 19);
  z-index: 1;
  text-align: center;
  box-shadow: rgb(172, 172, 172) 0.9px 0.9px 2px;
}

.cart__title {
  font-size: 1.65rem;
  margin-bottom: 2rem;
}

.cart__empty {
  color: rgb(169, 11, 11);
  font-size: 1rem;
}

.your__shopping__cart__is__empty {
  font-size: 1.1rem;
  color: rgb(190, 19, 19);
  margin: 0rem;

  transition: all 125ms ease-in-out;
}

.your__shopping__cart__is__empty:hover {
  color: rgb(157, 157, 157);
}

.your__shopping__cart__is__empty:active {
  color: black;
}

.cart__items__display {
  display: flex;
  flex-direction: row;
  width: 85%;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(169, 11, 11);
  border-radius: 0.25rem;
  margin-bottom: 0.6rem;
  box-shadow: rgb(111, 112, 112) 1px 1px 1.5px;
}

.items__images__descriptions {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.items__images__size {
  width: 80px;
  height: 100%;
  margin: 0.5rem;
  box-shadow: rgb(91, 91, 91) 0.8px 0.8px 2px;
}

.items__info {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0.5rem 0;
}

.items__info > h5 {
  font-size: 1.09rem;
  margin: 0.1rem;
  padding-bottom: 0.2rem;
  text-align: left;
}
.items__info > h6 {
  font-size: 0.88rem;
  margin: 0.1rem;
  padding: 0;
  text-align: left;
}

.items__quantity__price {
  color: white;
  margin-right: 1rem;
  margin-left: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.plus__minus__buttons {
  display: flex;
  flex-direction: column;
  margin-left: 0.6rem;
  margin-top: 0.8rem;
  margin-bottom: 0.5rem;
}

.plus__minus__buttons > button {
  color: rgb(178, 25, 25);
  background-color: #ff9900;
  border-radius: 0.35rem;
  border: 0.125rem solid rgb(178, 25, 25);
  box-shadow: rgb(255, 255, 255) 0.3px 0.3px 1.75px;
  outline: none;
  cursor: pointer;
  margin: 0.1rem;
}

.plus__minus__buttons > button:active {
  color: #ff9900;
  background-color: rgb(178, 25, 25);
  border-radius: 0.35rem;
  border: 0.125rem solid rgb(178, 25, 25);
  box-shadow: rgb(255, 255, 255) 0.3px 0.3px 1.75px;
  transform: translateY(0.5px);
}

.total__price {
  display: flex;
  flex-direction: row;
  margin-top: 0.8rem;
}

.items__price {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
}

.taxe__price {
  display: flex;
  flex-direction: row;
  margin-top: 0.25rem;
}

.move__to__the_left {
  align-items: left;
}

.price__items__taxe__total {
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
  margin-right: 0rem;
  justify-content: center;
  /* align-items: center; */
}
/* .not__shift {
  display: flex;
  flex-direction: row;
} */

.position__absolute {
  margin-left: 0rem;
}

.items__price__number {
  margin-left: 0rem;
}

.items__price__alphanumeric {
}

.number__taxe__price {
}

.number__total__price {
}

.checkout__button {
  font-size: 4rem;
  /* justify-content: center; */
  background: #b31a1a;
  border: 1px solid;
  border-radius: 3px;
  margin-top: 1.2rem;
  margin-bottom: 0.2rem;
  border-color: #af2c2c #a51919 #bb2525 #ce0c0c;
  color: rgb(255, 255, 255);
  padding: 2px 8px;
  text-align: center;
  font-size: 0.9rem;
  box-shadow: rgb(113, 113, 113) 1px 1px 1.5px;
  transition: background-color 2s, border-color 0.3, color 0.8s,
    transform 4 ease-in;
  outline: none;
  cursor: pointer;
  transition: all 200ms ease-in-out;
}

.checkout__button:hover {
  font-size: 4rem;
  justify-content: center;
  background: #bc3030;
  border: 1px solid;
  border-radius: 3px;
  margin-top: 1.2rem;
  margin-bottom: 0.2rem;
  border-color: #af2c2c #a51919 #bb2525 #ce0c0c;
  color: rgb(255, 255, 255);
  padding: 2px 8px;
  text-align: center;
  font-size: 0.9rem;
  box-shadow: rgb(185, 185, 185) 1px 1px 1.5px;
  transition: background-color 2s, border-color 0.3, color 0.8s,
    transform 4 ease-in;
  outline: none;
  cursor: pointer;
}

.checkout__button:active {
  background: #ffb116;
  border: 1px solid;
  border-radius: 3px;
  margin-top: 1.2rem;
  margin-bottom: 0.2rem;
  border-color: #e79d09 #eca008 #f4a508 #f2a408;
  color: #b31;
  padding: 2px 8px;
  text-align: center;
  font-size: 0.9rem;
  box-shadow: rgb(111, 112, 112) 1px 1px 1px;
  transform: translateY(0.2px);
  outline: none;
  cursor: pointer;
}

@media screen and (max-width: 570px) {
  .items__images__size {
    width: 60px;
    height: 100%;
    margin: 0.5rem;
    box-shadow: rgb(91, 91, 91) 0.8px 0.8px 2px;
  }
  .items__info > h5 {
    font-size: 0.85rem;
    margin: 0.1rem;
    padding-bottom: 0.2rem;
    text-align: left;
  }
  .items__info > h6 {
    font-size: 0.75rem;
    margin: 0.1rem;
    padding: 0;
    text-align: left;
  }

  .items__quantity__price {
    font-size: 0.75rem;
    color: white;
    margin-right: 0.25rem;
    margin-left: 0.25rem;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .plus__minus__buttons > button {
    display: flex;
    flex-direction: column;
    margin-left: 0.1rem;
    margin-top: 0.12rem;
    margin-bottom: 0.01rem;
    font-size: 0.45rem;
  }
}

/* .paywithcard__stripecheckout {

  display: flex;
  align-items: center;
  margin: 1rem;
} */

.pay__with__card {
  background: #b31a1a;
  border: 1px solid;
  border-radius: 3px;
  margin-top: 12px;
  margin-bottom: 8px;
  border-color: #af2c2c #a51919 #bb2525 #ce0c0c;
  color: rgb(255, 255, 255);
  padding: 5px 14px;
  text-align: center;
  font-size: 0.95rem;
  box-shadow: rgb(111, 112, 112) 1px 1px 2px;
  /* -webkit-transition-duration: 0.4s; /* Safari */
  transition: background-color 2s, border-color 0.3, color 0.8s,
    transform 4 ease-in;
  outline: none;
  cursor: pointer;
  transition: all 75ms ease-in-out;
}

.pay__with__card:hover {
  background: #b52b2b;
  /* background: #b31a1a; */
  border: 1px solid;
  border-radius: 3px;
  margin-top: 12px;
  margin-bottom: 8px;
  border-color: #af2c2c #a51919 #bb2525 #ce0c0c;
  color: rgb(255, 255, 255);
  padding: 5px 14px;
  text-align: center;
  font-size: 0.95rem;
  box-shadow: rgb(180, 180, 180) 1px 1px 2px;
  /* box-shadow: rgb(111, 112, 112) 1px 1px 2px; */
  transition: background-color 2s, border-color 0.3, color 0.8s,
    transform 4 ease-in;
  outline: none;
  cursor: pointer;
}

.pay__with__card:active {
  background: #ffb116;
  border: 1px solid;
  border-radius: 3px;
  margin-top: 12px;
  margin-bottom: 8px;
  border-color: #e79d09 #eca008 #f4a508 #f2a408;
  color: #b31;
  padding: 5px 14px;
  text-align: center;
  font-size: 0.95rem;
  box-shadow: rgb(111, 112, 112) 1px 1px 1px;
  transform: translateY(0.5px);
  outline: none;
  cursor: pointer;
  /* transition-duration: all 2s ease-in-out; */
}
