.footer__socials {
  color: var(--footer-font-color);
  font-size: 1.2rem;
  margin: 4px 4px;
}

.padding__socials {
  margin: 4px;
}

.socialsClassNameOption {
  text-decoration: none;
}

@media screen and (max-width: 570px) {
  .footer__socials {
    color: var(--footer-font-color);
    font-size: 0.75rem;
    margin: 4px 2px;
  }
  .padding__socials {
    margin: 2px;
  }
}
