.shoppingcarticon__red {
  color: rgb(190, 19, 19);
  height: calc(1px + 1.5vmin);
}

.shoppingcarticon__red:hover {
  color: #ff9900;
  height: calc(1px + 1.5vmin);
}

.shoppingcarticon__orange {
  color: #ff9900;
  height: calc(2px + 2vmin);
}

.shoppingcarticon__orange:hover {
  color: rgb(190, 19, 19);
  height: calc(2px + 2vmin);
}

.shoppingcarts {
  display: flex;
}

.shoppingcarts__centered {
  margin: 4.5rem auto 1.6rem auto;
}

.shoppingcarticon__orange__bigger {
  color: #ff9900;
  height: calc(6px + 2vmin);
}

.shoppingcarticon__orange__bigger:hover {
  color: rgb(190, 19, 19);
  height: calc(6px + 2vmin);
}
