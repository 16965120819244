html {
  font-size: 62.5%;
  box-sizing: border-box;
  overflow-x: hidden;
}

body,
#root {
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat-Regular', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Montserrat-Regular';
  src: url('./fonts/Montserrat-Regular/Montserrat-Regular.eot');
  src: url('./fonts/Montserrat-Regular/Montserrat-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('./fonts/Montserrat-Regular/Montserrat-Regular.woff2') format('woff2'),
    url('./fonts/Montserrat-Regular/Montserrat-Regular.woff') format('woff'),
    url('./fonts/Montserrat-Regular/Montserrat-Regular.ttf') format('truetype'),
    url('./fonts/Montserrat-Regular/Montserrat-Regular.svg#Montserrat-Regular')
      format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
