.home__page {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.home__image {
  z-index: -1;
  /* margin-bottom: -470px; */
  margin-bottom: -580px;
}

.horizontal__ligne {
  margin: 0.5rem;
  height: 1px;
  width: 100%;
  background-color: rgb(0, 0, 0);
  opacity: 0.75;
}

.horizontal__ligne__02 {
  margin: 3rem;
  height: 1px;
  width: 50%;
  background-color: rgb(0, 0, 0);
  opacity: 0.75;
}

.onearthwhatneeds__top {
  font-size: 1.5rem;
  margin: 0px 4px;
  margin-top: 2.5rem;
  color: black;
  transition: all 125ms ease-in-out;
}

.onearthwhatneeds__top:hover {
  color: rgb(111, 111, 111);
}

.onearthwhatneeds__top:active {
  color: rgb(190, 19, 19);
}

.thepurpose__of {
  font-size: 1.2rem;
  margin: 0px 4px;
  color: black;
  /* animation: changecolor 1s step-end both; */
  transition: all 125ms ease-in-out;
}

/* @keyframes changecolor {
  from {
    color: black;
  }
  to {
    color: rgb(190, 19, 19);
  }
} */

.thepurpose__of:hover {
  color: rgb(111, 111, 111);
}

.thepurpose__of:active {
  color: rgb(190, 19, 19);
}

.stripe__link {
  text-decoration: none;
  color: inherit;
  transition: all 125ms ease-in-out;
}

.stripe__link:hover {
  color: rgb(111, 111, 111);
}

.stripe__link:active {
  color: rgb(190, 19, 19);
}

.home__container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.98);
  max-width: 736px;
  /* max-width: 1380px; */
  width: 80%;
  border-radius: 0.25rem;
  margin: 0rem;
  /* margin-top: 0.45rem; */
  /* padding: 0.5rem; */
  border: 0.125rem solid rgb(190, 19, 19);
  z-index: 1;
  text-align: center;
  box-shadow: rgb(172, 172, 172) 0.9px 0.9px 2px;
}

.home__container > p {
  /* display: flex; */
  align-items: left;
  /* display: inline-block; */
  text-align: left;
  ligne-height: 1rem;
  /* text-align-last: left; */
  margin: 0.75rem 1rem;
}

.thepurpose__container {
  display: flex;
  align-items: center;
  /* display: inline-block; */
  text-align: center;
  ligne-height: 1rem;
  /* text-align-last: left; */
  margin: 2rem 1rem;
}

.home__video__container {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
}

.react__player {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 0;
}

.this__shopping__website {
  text-decoration: none;
  color: inherit;
  transition: all 125ms ease-in-out;
}
.this__shopping__website:hover {
  color: rgb(111, 111, 111);
}
.this__shopping__website:active {
  color: rgb(190, 19, 19);
}

.the__frontend {
  text-decoration: none;
  color: inherit;
  transition: all 125ms ease-in-out;
}
.the__frontend:hover {
  color: rgb(111, 111, 111);
}
.the__frontend:active {
  color: rgb(190, 19, 19);
}

.the__backend {
  text-decoration: none;
  color: inherit;
  transition: all 125ms ease-in-out;
}
.the__backend:hover {
  color: rgb(111, 111, 111);
}
.the__backend:active {
  color: rgb(190, 19, 19);
}

.gandhi__margintop__link {
  text-decoration: none;
  color: inherit;
  display: block;
  margin-top: 0.2rem;

  transition: all 125ms ease-in-out;
}

.gandhi__margintop__link:hover {
  color: rgb(111, 111, 111);
}
.gandhi__margintop__link:active {
  color: rgb(190, 19, 19);
}

.home__products__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  margin-left: 2px;
  margin-right: 2px;
  max-width: 1080px;
  height: auto;
}

.home__row__container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  z-index: 1;
}

.product {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(255, 255, 255);
  border-radius: 16px;
  margin: 8px 8px;
  padding: 6px;
  width: 100%;
  max-height: 310px;
  min-width: 280px;
  max-width: 310px;
  border: 1px solid rgb(250, 250, 250);
  z-index: 1;
  text-align: center;
  box-shadow: rgb(172, 172, 172) 1px 1px 1.8px;
}

.onearthwhatneeds__bottom {
  font-size: 0.9rem;
  margin: 0px 4px;
  margin-bottom: 0.6rem;
  color: black;
  transition: all 125ms ease-in-out;
}

.onearthwhatneeds__bottom:hover {
  color: rgb(111, 111, 111);
}

.onearthwhatneeds__bottom:active {
  color: rgb(190, 19, 19);
}

@media screen and (max-width: 320px) {
  .home__container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.98);
    max-width: 300px;
    width: 80%;
    border-radius: 0.5rem;
    margin: 0rem;
    min-width: auto;
    min-height: auto;
    /* margin-top: 0.45rem; */
    /* padding: 0.5rem; */
    border: 0.125rem solid rgb(190, 19, 19);
    z-index: 1;
    text-align: center;
    box-shadow: rgb(172, 172, 172) 0.9px 0.9px 2px;
  }
}
