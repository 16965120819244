.products {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.products__image {
  z-index: -1;
  margin-bottom: -580px;
}

.products__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.98);
  max-width: 1040px;
  /* max-width: 1160px; */
  min-height: 80vh;
  width: 90%;
  border-radius: 0.25rem;
  margin: 1rem;
  margin-top: 3.3rem;
  padding: 1rem;
  border: 0.125rem solid rgb(190, 19, 19);
  z-index: 1;
  text-align: center;
  box-shadow: rgb(172, 172, 172) 0.9px 0.9px 2px;
}

.home__products__container {
  display: flex;
  flex-direction: column;
}

.our__products__title {
  font-size: 1.65rem;
  margin-bottom: 4rem;
}
