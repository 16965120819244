footer {
  display: flex;
  height: var(--footer-height);
  width: 100%;
  background-color: var(--footer-background-color);
  z-index: 194;
}

.footer__layout {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  margin: 0px auto;
  max-width: var(--footer-max-width);
  color: var(--footer-font-color);
  padding: 0.3rem;
}

.footer__layout__copyright {
  font-size: 0.68rem;
  margin: 4px 8px;
  margin-right: 2rem;
  color: var(--footer-font-color);
  transition: all 125ms ease-in-out;
}

.footer__layout__copyright:hover {
  color: rgb(255, 255, 255);
}

.footer__layout__copyright:active {
  color: rgb(200, 200, 200);
}

/* .footer__navlink__copyright {
  text-decoration: none !important;
} */

.footer__navbar {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  margin: 4px 4px;
}

@media screen and (max-width: 570px) {
  .footer__navbar {
    display: flex;
    flex-direction: column;
    font-size: 11px;
    margin: 4px 4px;
  }
  .footer__layout__copyright {
    font-size: 0.58rem;
    margin: 4px 8px;
    margin-right: 2rem;
    color: var(--footer-font-color);
    transition: all 125ms ease-in-out;
  }
}

.nav__link__footer {
  color: var(--footer-font-color);
  text-decoration: none;
  transition: all 125ms ease-in-out;
}

.nav__link__footer:hover {
  color: rgb(255, 255, 255);
  text-decoration: none;
}

.nav__link__footer:active {
  color: rgb(200, 200, 200);
}

.footer__socials__option {
  text-decoration: none;
}

.footer__socials {
  padding: 0.3rem;
}

/* Twitter */

.socialsClassNameOption01f {
  text-decoration: none;
  color: rgb(105, 105, 105);
  margin: 0rem 0.25rem;
  transition: all 200ms ease-in-out;
}

.socialsClassNameOption01f:hover {
  text-decoration: none;
  color: #1da1f2;
  margin: 0rem 0.25rem;
}

.socialsClassNameOption01f:active {
  text-decoration: none;
  color: #1da1f2;
  transform: translateY(0.5px);
  opacity: 0.65;
  margin: 0rem 0.25rem;
}

/* Instagram */

.socialsClassNameOption02f {
  text-decoration: none;
  color: rgb(105, 105, 105);
  margin: 0rem 0.25rem;
  transition: all 200ms ease-in-out;
}

.socialsClassNameOption02f:hover {
  text-decoration: none;
  color: #fb3958;
  margin: 0rem 0.25rem;
}

.socialsClassNameOption02f:active {
  text-decoration: none;
  color: #fcaf45;
  transform: translateY(0.5px);
  opacity: 1;
  margin: 0rem 0.25rem;
}

/* YouTube */

.socialsClassNameOption03f {
  text-decoration: none;
  color: rgb(105, 105, 105);
  margin: 0rem 0.4rem;
  transition: all 200ms ease-in-out;
}

.socialsClassNameOption03f:hover {
  text-decoration: none;
  color: #ff0000;
  margin: 0rem 0.4rem;
}

.socialsClassNameOption03f:active {
  text-decoration: none;
  color: #ff0000;
  transform: translateY(0.5px);
  opacity: 0.65;
  margin: 0rem 0.4rem;
}

/* LinkedIn */

.socialsClassNameOption04f {
  text-decoration: none;
  color: rgb(105, 105, 105);
  margin: 0rem 0.25rem;
  transition: all 200ms ease-in-out;
}

.socialsClassNameOption04f:hover {
  text-decoration: none;
  color: #0e76a8;
  margin: 0rem 0.25rem;
}

.socialsClassNameOption04f:active {
  text-decoration: none;
  color: #0e76a8;
  transform: translateY(0.5px);
  opacity: 0.75;
  margin: 0rem 0.25rem;
}

/* TikTok */

.socialsClassNameOption05f {
  text-decoration: none;
  color: rgb(105, 105, 105);
  margin: 0rem 0.25rem;
  transition: all 200ms ease-in-out;
}

.socialsClassNameOption05f:hover {
  text-decoration: none;
  color: #4de8f4;
  margin: 0rem 0.25rem;
}

.socialsClassNameOption05f:active {
  text-decoration: none;
  color: #fd3e3e;
  transform: translateY(0.5px);
  opacity: 1;
  margin: 0rem 0.25rem;
}

/* Facebook */

.socialsClassNameOption06f {
  text-decoration: none;
  color: rgb(105, 105, 105);
  margin: 0rem 0.04rem;
  transition: all 200ms ease-in-out;
}

.socialsClassNameOption06f:hover {
  text-decoration: none;
  color: #4267b2;
  margin: 0rem 0.04rem;
}

.socialsClassNameOption06f:active {
  text-decoration: none;
  color: #4267b2;
  transform: translateY(0.5px);
  opacity: 0.65;
  margin: 0rem 0.04rem;
}

/* GitHub */

.socialsClassNameOption07f {
  text-decoration: none;
  color: rgb(105, 105, 105);
  margin: 0rem 0.25rem;
  transition: all 200ms ease-in-out;
}

.socialsClassNameOption07f:hover {
  text-decoration: none;
  color: #ad5c51;
  margin: 0rem 0.25rem;
}

.socialsClassNameOption07f:active {
  text-decoration: none;
  color: #f4cbb2;
  transform: translateY(0.5px);
  opacity: 1;
  margin: 0rem 0.25rem;
}

@media screen and (max-width: 320px) {
  .footer__navbar {
    display: flex;
    flex-direction: column;
    font-size: 8px;
    margin: 2px 2px;
  }
  .footer__layout__copyright {
    font-size: 0.28rem;
    margin: 2px 4px;
    margin-right: 0.25rem;
    color: var(--footer-font-color);
    transition: all 125ms ease-in-out;
  }
}

.nav__link__footer {
  color: var(--footer-font-color);
  text-decoration: none;
  transition: all 125ms ease-in-out;
}

.nav__link__footer:hover {
  color: rgb(255, 255, 255);
  text-decoration: none;
}

.nav__link__footer:active {
  color: rgb(200, 200, 200);
}

.footer__socials__option {
  text-decoration: none;
}

.footer__socials {
  padding: 0.15rem;
}

/* Twitter */

.socialsClassNameOption01f {
  text-decoration: none;
  color: rgb(105, 105, 105);
  margin: 0rem 0.25rem;
  transition: all 200ms ease-in-out;
}

.socialsClassNameOption01f:hover {
  text-decoration: none;
  color: #1da1f2;
  margin: 0rem 0.25rem;
}

.socialsClassNameOption01f:active {
  text-decoration: none;
  color: #1da1f2;
  transform: translateY(0.5px);
  opacity: 0.65;
  margin: 0rem 0.25rem;
}

/* Instagram */

.socialsClassNameOption02f {
  text-decoration: none;
  color: rgb(105, 105, 105);
  margin: 0rem 0.25rem;
  transition: all 200ms ease-in-out;
}

.socialsClassNameOption02f:hover {
  text-decoration: none;
  color: #fb3958;
  margin: 0rem 0.25rem;
}

.socialsClassNameOption02f:active {
  text-decoration: none;
  color: #fcaf45;
  transform: translateY(0.5px);
  opacity: 1;
  margin: 0rem 0.25rem;
}

/* YouTube */

.socialsClassNameOption03f {
  text-decoration: none;
  color: rgb(105, 105, 105);
  margin: 0rem 0.4rem;
  transition: all 200ms ease-in-out;
}

.socialsClassNameOption03f:hover {
  text-decoration: none;
  color: #ff0000;
  margin: 0rem 0.4rem;
}

.socialsClassNameOption03f:active {
  text-decoration: none;
  color: #ff0000;
  transform: translateY(0.5px);
  opacity: 0.65;
  margin: 0rem 0.4rem;
}

/* LinkedIn */

.socialsClassNameOption04f {
  text-decoration: none;
  color: rgb(105, 105, 105);
  margin: 0rem 0.25rem;
  transition: all 200ms ease-in-out;
}

.socialsClassNameOption04f:hover {
  text-decoration: none;
  color: #0e76a8;
  margin: 0rem 0.25rem;
}

.socialsClassNameOption04f:active {
  text-decoration: none;
  color: #0e76a8;
  transform: translateY(0.5px);
  opacity: 0.75;
  margin: 0rem 0.25rem;
}

/* TikTok */

.socialsClassNameOption05f {
  text-decoration: none;
  color: rgb(105, 105, 105);
  margin: 0rem 0.25rem;
  transition: all 200ms ease-in-out;
}

.socialsClassNameOption05f:hover {
  text-decoration: none;
  color: #4de8f4;
  margin: 0rem 0.25rem;
}

.socialsClassNameOption05f:active {
  text-decoration: none;
  color: #fd3e3e;
  transform: translateY(0.5px);
  opacity: 1;
  margin: 0rem 0.25rem;
}

/* Facebook */

.socialsClassNameOption06f {
  text-decoration: none;
  color: rgb(105, 105, 105);
  margin: 0rem 0.04rem;
  transition: all 200ms ease-in-out;
}

.socialsClassNameOption06f:hover {
  text-decoration: none;
  color: #4267b2;
  margin: 0rem 0.04rem;
}

.socialsClassNameOption06f:active {
  text-decoration: none;
  color: #4267b2;
  transform: translateY(0.5px);
  opacity: 0.65;
  margin: 0rem 0.04rem;
}

/* GitHub */

.socialsClassNameOption07f {
  text-decoration: none;
  color: rgb(105, 105, 105);
  margin: 0rem 0.25rem;
  transition: all 200ms ease-in-out;
}

.socialsClassNameOption07f:hover {
  text-decoration: none;
  color: #ad5c51;
  margin: 0rem 0.25rem;
}

.socialsClassNameOption07f:active {
  text-decoration: none;
  color: #f4cbb2;
  transform: translateY(0.5px);
  opacity: 1;
  margin: 0rem 0.25rem;
}
