.product__detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.products__image {
  z-index: -1;
  margin-bottom: -600px;
}

.product__detail__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.98);
  max-width: 1160px;
  min-height: 80vh;
  width: 88%;
  border-radius: 0.25rem;
  margin: 1rem;
  padding: 3rem;
  border: 0.125rem solid rgb(190, 19, 19);
  z-index: 1;
  text-align: center;
  box-shadow: rgb(172, 172, 172) 0.9px 0.9px 2px;
}

.product__detail__background {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(255, 255, 255);
  border-radius: 4px;
  margin: 8px 8px;
  padding: 6px;
  width: 100%;
  min-width: 20rem;
  border: 1px solid rgb(250, 250, 250);
  z-index: 1;
  text-align: center;
  box-shadow: rgb(172, 172, 172) 0.5px 0.5px 1.5px;
}

.product__detail__title {
  padding-top: 1.2rem;
}

.product__detail__background > h3,
h4,
h6,
p {
  margin: 0.125rem;
}

#product__image__detail {
  max-width: 40rem;
  width: 94%;
  object-fit: contain;
  margin-top: 1rem;
}
