:root {
  --header-height: 46px;
  --header-height-small: 36px;
  --footer-height: 80px;
  --header-background-color: rgb(0, 0, 0);
  --footer-background-color: rgb(0, 0, 0);
  --header-max-width: 1048px;
  --footer-max-width: 988px;
  --header-font-color: rgb(255, 255, 255);
  --footer-font-color: rgb(140, 140, 140);
  --header-margin-small: 2rem;
  --header-margin-big: 4rem;
  font-size: 0.9rem;
  background-color: rgb(245, 245, 245);
}

html:focus-within {
  scroll-behavior: smooth;
}

.App {
  overflow: visible;
}

@media screen and (max-width: 570px) {
  :root {
    --header-height: 46px;
    --header-height-small: 36px;
    --footer-height: 80px;
    --header-background-color: rgb(0, 0, 0);
    --footer-background-color: rgb(0, 0, 0);
    --header-max-width: 1048px;
    --footer-max-width: 988px;
    --header-font-color: rgb(255, 255, 255);
    --footer-font-color: rgb(140, 140, 140);
    --header-margin-small: 2rem;
    --header-margin-big: 4rem;
    font-size: 0.8rem;
    background-color: rgb(245, 245, 245);
  }
}

@media screen and (max-width: 320px) {
  :root {
    --header-height: 30px;
    --header-height-small: 20px;
    --footer-height: 80px;
    --header-background-color: rgb(0, 0, 0);
    --footer-background-color: rgb(0, 0, 0);
    --header-max-width: 1048px;
    --footer-max-width: 988px;
    --header-font-color: rgb(255, 255, 255);
    --footer-font-color: rgb(140, 140, 140);
    --header-margin-small: 0.2rem;
    --header-margin-big: 0.2rem;
    font-size: 0.45rem;
    background-color: rgb(245, 245, 245);
  }
}
