header {
  height: var(--header-height);
  width: 100%;
  background-color: var(--header-background-color);
  z-index: 194;
  position: sticky;
  top: 0;
}

.header__layout {
  height: var(--header-height);
  max-width: var(--header-max-width);
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.header__logo {
  height: var(--header-height);
  margin: 0 var(--header-margin-small);
  transition: opacity 125ms ease-in-out;
}

.header__logo:hover {
  opacity: 0.5;
  text-decoration: none;
  transform: translateY(0.01px);
}

.header__logo:active {
  opacity: 0.85;
}

.header__icon__number__item {
  display: flex;
  flex-direction: row;
  margin: 0.45rem var(--header-margin-small);
  align-items: center;
  transition: opacity 125ms ease-in-out;
}

.header__icon__number__item:hover {
  opacity: 0.5;
}

.header__icon__number__item:active {
  opacity: 0.8;
}

.header__shopping__cart__icon {
  color: var(--header-font-color);
  height: 25px;
}

.header__shopping__number__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 5px;
}

.header__shopping__number {
  font-size: 14px;
  color: var(--header-font-color);
}

.header__shopping__item {
  font-size: 11px;
  color: var(--header-font-color);
}

.nav__link__header {
  color: white;
  text-decoration: none;
  margin: 0.5rem;
  transition: all 125ms ease-in-out;
}

.nav__link__header:hover {
  color: rgb(150, 150, 150);
  text-decoration: none;
}

.nav__link__header:active {
  color: rgb(241, 241, 241);
}

@media screen and (max-width: 570px) {
  .header__layout {
    height: var(--header-height);
    max-width: var(--header-max-width);
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  .header__logo {
    height: 2rem;
    margin: 0.75rem 0.5rem;
    transition: opacity 125ms ease-in-out;
  }

  .header__logo:hover {
    opacity: 0.5;
    text-decoration: none;
    transform: translateY(0.01px);
  }

  .header__logo:active {
    opacity: 0.85;
  }

  .header__icon__number__item {
    display: flex;
    flex-direction: row;
    margin: 0.85rem 0.5rem;
    align-items: center;
    transition: opacity 125ms ease-in-out;
  }

  .header__icon__number__item:hover {
    opacity: 0.5;
  }

  .header__icon__number__item:active {
    opacity: 0.8;
  }

  .header__shopping__cart__icon {
    color: var(--header-font-color);
    height: 20px;
  }

  .header__shopping__number__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 5px;
  }

  .header__shopping__number {
    font-size: 12px;
    color: var(--header-font-color);
  }

  .header__shopping__item {
    font-size: 9px;
    color: var(--header-font-color);
  }

  .nav__link__header {
    font-size: 0.9rem;
    color: white;
    text-decoration: none;
    margin: 0.5rem;
    transition: all 125ms ease-in-out;
  }

  .nav__link__header:hover {
    color: rgb(150, 150, 150);
    text-decoration: none;
  }

  .nav__link__header:active {
    color: rgb(241, 241, 241);
  }
}

@media screen and (max-width: 320px) {
  .header__layout {
    height: var(--header-height);
    max-width: var(--header-max-width);
    margin: 0.2rem;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .header__logo {
    height: var(--header-height-small);
    margin: 0.5rem var(--header-margin-small);
    transition: opacity 125ms ease-in-out;
  }

  .header__logo:hover {
    opacity: 0.5;
    text-decoration: none;
    transform: translateY(0.01px);
  }

  .header__logo:active {
    opacity: 0.85;
  }

  .header__icon__number__item {
    display: flex;
    flex-direction: row;
    margin: 0.85rem var(--header-margin-small);
    align-items: center;
    transition: opacity 125ms ease-in-out;
  }

  .header__icon__number__item:hover {
    opacity: 0.5;
  }

  .header__icon__number__item:active {
    opacity: 0.8;
  }

  .header__shopping__cart__icon {
    color: var(--header-font-color);
    height: 11px;
  }

  .header__shopping__number__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 2px;
  }

  .header__shopping__number {
    font-size: 7px;
    color: var(--header-font-color);
  }

  .header__shopping__item {
    font-size: 5px;
    color: var(--header-font-color);
  }

  .nav__link__header {
    color: white;
    text-decoration: none;
    margin: 0.5rem;
    transition: all 125ms ease-in-out;
  }

  .nav__link__header:hover {
    color: rgb(150, 150, 150);
    text-decoration: none;
  }

  .nav__link__header:active {
    color: rgb(241, 241, 241);
  }
}
