.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.contact__image {
  z-index: -1;
  margin-bottom: -580px;
}

.contact__form__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.98);
  max-width: 45rem;
  min-height: 80vh;
  width: 70%;
  border-radius: 0.25rem;
  margin: 1rem;
  margin-bottom: 1.4rem;
  padding: 3rem;
  border: 0.125rem solid rgb(190, 19, 19);
  z-index: 1;
  text-align: center;
  box-shadow: rgb(172, 172, 172) 0.9px 0.9px 2px;
}

#emailjs__form {
  z-index: 1;
}

.contact__form__title {
  font-size: 1.65rem;
}

.contact__form__form {
  display: flex;
  flex-direction: column;
  margin: 6rem 0rem;
}

input[type='text'] select,
input {
  box-sizing: border-box;
  padding: 0.35rem;
  resize: vertical;
  border-radius: 0.25rem;
  border: 1px solid #ccc;
  margin-bottom: 1rem;
  font-size: 0.84rem;
}

input[type='text'] textarea {
  box-sizing: border-box;
  padding: 0.35rem;
  resize: vertical;
  border-radius: 0.25rem;
  border: 1px solid #ccc;
  margin-bottom: 1rem;
  font-size: 0.84rem;
}

.contact__form__form > textarea {
  resize: vertical;
  font-size: 0.87rem;
  min-height: 8rem;
  max-height: 52rem;
  height: 20rem;
}

.contact__form__form > label {
  text-align: left;
  padding: 0.2rem 0rem;
}

.contact__form__your__message,
input {
  font-family: 'Montserrat-Regular';
}

.chars__left {
  font-size: 0.78rem;
  text-align: left;
  padding: 0rem 0rem;
  margin-top: 0.25rem;
  margin-bottom: 1rem;
}

.send__your__message__button {
  font-size: 4rem;
  justify-content: center;
  background: #b31a1a;
  border: 1px solid;
  border-radius: 3px;
  margin-top: 1.2rem;
  margin-bottom: 0.2rem;
  border-color: #af2c2c #a51919 #bb2525 #ce0c0c;
  color: rgb(255, 255, 255);
  padding: 2px 8px;
  text-align: center;
  font-size: 0.9rem;
  box-shadow: rgb(111, 112, 112) 1px 1px 1.5px;
  transition: background-color 2s, border-color 0.3, color 0.8s,
    transform 4 ease-in;
  outline: none;
  cursor: pointer;
}

.send__your__message__button:active {
  background: #ffb116;
  border: 1px solid;
  border-radius: 3px;
  margin-top: 1.2rem;
  margin-bottom: 0.2rem;
  border-color: #e79d09 #eca008 #f4a508 #f2a408;
  color: #b31;
  padding: 2px 8px;
  text-align: center;
  font-size: 0.9rem;
  box-shadow: rgb(111, 112, 112) 1px 1px 1px;
  transform: translateY(0.5px);
  outline: none;
  cursor: pointer;
}

.status-message {
  opacity: 0;
  color: #b31a1a;
}
.success {
  opacity: 1;
  color: #b31a1a;
}
.failure {
  opacity: 1;
  color: #b31a1a;
}
