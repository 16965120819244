.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.about__image {
  z-index: -1;
  margin-bottom: -554px;
}

.about__title {
  font-size: 1.4rem;
  margin: 0;
}

.about__container_01 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: white;
  max-width: 100%;
  height: 1rem;
  border-radius: 0.25rem;
  margin-top: 0.5rem;
  margin: 0.4rem;
  padding: 1.8rem;
  border: 0.125rem solid rgb(190, 19, 19);
  z-index: 1;
  text-align: center;
  box-shadow: rgb(40, 40, 40) 0.9px 0.9px 2px;
  opacity: 0.95;
}

.today__s__date {
  min-width: 17.1rem;
  margin: 0.05rem;
}

.today__s__date:active {
  opacity: 0.15;
}

.today__s__date > h5 {
  color: rgb(190, 19, 19);
  font-size: 0.73rem;
  margin: 0;
}

.today__s__date > h6 {
  color: rgb(190, 19, 19);
  font-size: 0.68rem;
  margin: 0;
}

.on__earth__image {
  max-width: 70rem;
  width: 92%;
  height: auto;
  border-radius: 0.25rem;
  border: 0.125rem solid rgb(190, 19, 19);
  margin: 0.4rem;
  box-shadow: rgb(40, 40, 40) 0.9px 0.9px 2px;
  opacity: 1;
}

.on__earth__image:active {
  opacity: 0.5;
}

.cursor__default {
  transition: all 125ms ease-in-out;
  cursor: default;
  text-decoration: none;
  color: black;
}

.cursor__default:hover {
  cursor: pointer;
  color: rgb(111, 111, 111);
}

.cursor__default:active {
  cursor: pointer;
  color: rgb(190, 19, 19);
}

.cursor__default > p {
  font-size: 1.44rem;
  margin: 0.2rem;
}

#listening__justify {
  padding-right: calc(10vw + 10vh + 5vmin);
  margin: 1rem;
}

#visualizing__justify {
  padding-left: calc(0.5vw + 0.5vh + 0.2vmin);
  margin: 0rem;
}

#creating__justify {
  padding-left: calc(10vw + 10vh + 5vmin);
  margin: 1rem;
}

.about__container_02 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  max-width: 23.5rem;
  min-height: 0vh;
  border-radius: 0.3rem;
  margin: 0.4rem;
  padding: 0.95rem;
  border: 0.125rem solid rgb(190, 19, 19);
  z-index: 1;
  text-align: center;
  box-shadow: rgb(40, 40, 40) 0.9px 0.9px 2px;
  opacity: 0.95;
}

.about__container_02 > h2 {
  margin: 0.2rem;
}

.about__container_03 {
  display: flex;
  flex-direction: column;
  background-color: white;
  max-width: 40rem;
  width: 80%;
  border-radius: 0.3rem;
  margin: 0.4rem;
  padding: 0.5rem;
  border: 0.125rem solid rgb(190, 19, 19);
  z-index: 1;
  text-align: center;
  box-shadow: rgb(40, 40, 40) 0.9px 0.9px 2px;
  opacity: 0.97;
}

.about__container_03 > p {
  padding: 0;
  margin: 0.25rem;
  font-size: 1.6rem;
}

.about__container_04 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  text-align: center;
  opacity: 0.95;
}
