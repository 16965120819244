.ourcompany__ourproducts__contact {
  color: var(--header-font-color);
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  flex: 0.92;
  align-items: center;
  margin: 0 0.8rem;
}

.navBar__option {
  margin: 0 0.8rem;
}

.link {
  text-decoration: none;
}

@media screen and (max-width: 570px) {
  .ourcompany__ourproducts__contact {
    color: var(--header-font-color);
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    flex: 1;
    align-items: center;
    margin: 0 0.1rem;
    font-size: 0.9rem;
  }
  .navBar__option {
    margin: 0 0.2rem;
  }
}
