.side__container {
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 0;
  top: calc(35% - 3vw);
  z-index: 194;
  font-size: calc(4px + 0.5vw + 0.5vh + 0.5vmin);
  color: rgb(0, 0, 0);
  border: 1px solid;
  border-color: rgb(40, 40, 40);
  border-radius: calc(8px + 1vw + 0.2vh + 0.01vmin);
  margin-right: 14px;
  box-shadow: rgb(102, 102, 102) 0px 0px 1px;
}

/* Twitter */

.socialsClassNameOption01 {
  margin: calc(0.35vw + 0.2vh + 0.01vmin) calc(0.2vw + 0.2vh + 0.01vmin);
  text-decoration: none;
  color: #1da1f2;
  transition: all 200ms ease-in-out;
}

.socialsClassNameOption01:hover {
  margin: calc(0.35vw + 0.2vh + 0.01vmin) calc(0.2vw + 0.2vh + 0.01vmin);
  text-decoration: none;
  color: #282828;
  transform: translateY(0.25px);
  opacity: 1;
}

.socialsClassNameOption01:active {
  margin: calc(0.35vw + 0.2vh + 0.01vmin) calc(0.2vw + 0.2vh + 0.01vmin);
  text-decoration: none;
  color: #1da1f2;
  transform: translateY(0.5px);
  opacity: 0.8;
}

/* Instagram */

.socialsClassNameOption02 {
  margin: calc(0.35vw + 0.2vh + 0.01vmin) calc(0.2vw + 0.2vh + 0.01vmin);
  text-decoration: none;
  color: #fd1d1d;
  transition: all 200ms ease-in-out;
}

.socialsClassNameOption02:hover {
  margin: calc(0.35vw + 0.2vh + 0.01vmin) calc(0.2vw + 0.2vh + 0.01vmin);
  text-decoration: none;
  color: #282828;
  transform: translateY(0.25px);
  opacity: 1;
}

.socialsClassNameOption02:active {
  margin: calc(0.35vw + 0.2vh + 0.01vmin) calc(0.2vw + 0.2vh + 0.01vmin);
  text-decoration: none;
  color: #fd1d1d;
  transform: translateY(0.5px);
  opacity: 0.8;
}

/* YouTube */

.socialsClassNameOption03 {
  margin: calc(0.35vw + 0.2vh + 0.01vmin) calc(0.2vw + 0.2vh + 0.01vmin);
  text-decoration: none;
  color: #ff0000;
  transition: all 200ms ease-in-out;
}

.socialsClassNameOption03:hover {
  margin: calc(0.35vw + 0.2vh + 0.01vmin) calc(0.2vw + 0.2vh + 0.01vmin);
  text-decoration: none;
  color: #282828;
  transform: translateY(0.25px);
  opacity: 1;
}

.socialsClassNameOption03:active {
  margin: calc(0.35vw + 0.2vh + 0.01vmin) calc(0.2vw + 0.2vh + 0.01vmin);
  text-decoration: none;
  color: #ff0000;
  transform: translateY(0.5px);
  opacity: 0.8;
}

/* LinkedIn */

.socialsClassNameOption04 {
  margin: calc(0.35vw + 0.2vh + 0.01vmin) calc(0.2vw + 0.2vh + 0.01vmin);
  text-decoration: none;
  color: #2867b2;
  transition: all 200ms ease-in-out;
}

.socialsClassNameOption04:hover {
  margin: calc(0.35vw + 0.2vh + 0.01vmin) calc(0.2vw + 0.2vh + 0.01vmin);
  text-decoration: none;
  color: #282828;
  transform: translateY(0.25px);
  opacity: 1;
}

.socialsClassNameOption04:active {
  margin: calc(0.35vw + 0.2vh + 0.01vmin) calc(0.2vw + 0.2vh + 0.01vmin);
  text-decoration: none;
  color: #0e76a8;
  transform: translateY(0.5px);
  opacity: 0.5;
}

/* TikTok */

.socialsClassNameOption05 {
  margin: calc(0.35vw + 0.2vh + 0.01vmin) calc(0.2vw + 0.2vh + 0.01vmin);
  text-decoration: none;
  color: #4de8f4;
  transition: all 200ms ease-in-out;
}

.socialsClassNameOption05:hover {
  margin: calc(0.35vw + 0.2vh + 0.01vmin) calc(0.2vw + 0.2vh + 0.01vmin);
  text-decoration: none;
  color: #fd3e3e;
  transform: translateY(0.25px);
  opacity: 1;
}

.socialsClassNameOption05:active {
  margin: calc(0.35vw + 0.2vh + 0.01vmin) calc(0.2vw + 0.2vh + 0.01vmin);
  text-decoration: none;
  color: #4de8f4;
  transform: translateY(0.5px);
  opacity: 1;
}

/* Facebook */

.socialsClassNameOption06 {
  margin: calc(0.35vw + 0.2vh + 0.01vmin) calc(0.2vw + 0.2vh + 0.01vmin);
  text-decoration: none;
  color: #4267b2;
  transition: all 200ms ease-in-out;
}

.socialsClassNameOption06:hover {
  margin: calc(0.35vw + 0.2vh + 0.01vmin) calc(0.2vw + 0.2vh + 0.01vmin);
  text-decoration: none;
  color: #282828;
  transform: translateY(0.25px);
  opacity: 1;
}

.socialsClassNameOption06:active {
  margin: calc(0.35vw + 0.2vh + 0.01vmin) calc(0.2vw + 0.2vh + 0.01vmin);
  text-decoration: none;
  color: #4267b2;
  transform: translateY(0.5px);
  opacity: 0.5;
}

/* GitHub */

.socialsClassNameOption07 {
  margin: calc(0.35vw + 0.2vh + 0.01vmin) calc(0.2vw + 0.2vh + 0.01vmin);
  text-decoration: none;
  color: #ad5c51;
  transition: all 200ms ease-in-out;
}

.socialsClassNameOption07:hover {
  margin: calc(0.35vw + 0.2vh + 0.01vmin) calc(0.2vw + 0.2vh + 0.01vmin);
  text-decoration: none;
  color: #282828;
  transform: translateY(0.25px);
  opacity: 1;
}

.socialsClassNameOption07:active {
  margin: calc(0.35vw + 0.2vh + 0.01vmin) calc(0.2vw + 0.2vh + 0.01vmin);
  text-decoration: none;
  color: #ad5c51;
  transform: translateY(0.5px);
  opacity: 1;
}
